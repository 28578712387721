
.normal-flag {
  float: left;
  margin-top: -8px !important;
}
.card {
  padding: 15px 10px !important;
  margin-top: 10px;
  &.columns {
    margin-left: 0;
    margin-right: 0;
    .column.hide-mobile {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .column.hide-tablet {
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 750px) {
    padding: 5px 0 !important;
  }
}
