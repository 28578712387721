
.normal-flag {
  float: left;
  margin-top: -8px !important;
}

.book-avatar {
  height: 57px;
  border-radius: 10px;
}
